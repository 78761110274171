import React from 'react'
import SocialLinks from '../components/SocialLinks'
import creditCards from '../images/svg/credit-card.svg'
import paypal from '../images/svg/paypal-apple-pay.svg'

import { Link } from 'gatsby'
import Image from './Lendis/Image'

const Footer = () => {
  const newDate = new Date()
  const year = newDate.getFullYear()

  const bgImage = process.env.CLOUDINARY_URL + '/f_auto,w_1440/website/44_stock_office_wdefna.jpg'
  return (
    <>
      <div className="bg-lendis-black relative  lg:mt-0   h-128 ">
        <div
          className="absolute inset-0 opacity-50 bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${bgImage})` }}
        />
        <div className="pl-8 top-0 relative left-0 text-white mx-auto container pt-40 lg:w-2/3 lg:flex lg:items-center">
          <div className="lg:w-1/3">
            <svg
              width="27"
              height="24"
              viewBox="0 0 27 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.1007 1.14725L25.2528 2.56471C26.1501 3.66863 26.2964 5.25501 25.6505 6.53155C21.8177 14.1065 15.7246 19.7658 8.31621 23.6478C6.99578 24.3397 5.43277 23.9848 4.48871 22.8233C4.01757 22.2436 3.46749 21.6848 3.08814 21.0353C2.16354 19.4524 2.56515 17.3236 4.04761 16.2606L8.2898 13.2187C9.0921 12.6434 10.1563 12.7418 10.8477 13.4449L11.5151 14.1234C13.5594 12.9213 15.2144 11.2639 16.508 9.11184L15.8951 8.06184C15.4412 7.2842 15.493 6.28322 16.0244 5.55808L19.1417 1.304C20.3498 -0.344569 22.7889 -0.466565 24.1007 1.14725ZM22.7556 2.27548C22.1546 1.5361 21.0877 1.61594 20.5409 2.36209L17.4236 6.61618C17.0347 7.14681 18.0215 8.23044 18.2714 8.65868C18.4313 8.93255 18.4342 9.27251 18.2791 9.54916C16.6894 12.3837 14.5049 14.5543 11.7641 16.0058C11.4263 16.1847 11.0132 16.1202 10.7439 15.8464L9.61378 14.6973C9.51791 14.5998 9.39874 14.5925 9.29569 14.6664L5.0535 17.7082C4.34491 18.2163 4.10865 19.31 4.58824 20.1311C4.9193 20.6978 5.42208 21.1886 5.83376 21.6951C6.27335 22.2359 6.95031 22.3697 7.51657 22.073C14.6036 18.3594 20.4318 12.9695 24.0991 5.72148C24.4368 5.05403 24.3466 4.23287 23.9078 3.69294L22.7556 2.27548Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.3465 6.08464C8.22035 6.08464 6.49676 7.80824 6.49676 9.9344C6.49676 10.4727 6.06037 10.9091 5.52207 10.9091C4.98377 10.9091 4.54738 10.4727 4.54738 9.9344C4.54738 6.73163 7.14374 4.13526 10.3465 4.13526C10.8848 4.13526 11.3212 4.57164 11.3212 5.10995C11.3212 5.64825 10.8848 6.08464 10.3465 6.08464Z"
                fill="#00AF54"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.3465 1.94938C5.93649 1.94938 2.36148 5.52439 2.36148 9.93439C2.36148 10.4727 1.9251 10.9091 1.38679 10.9091C0.848491 10.9091 0.412109 10.4727 0.412109 9.93439C0.412109 4.44778 4.85988 0 10.3465 0C10.8848 0 11.3212 0.436383 11.3212 0.974688C11.3212 1.51299 10.8848 1.94938 10.3465 1.94938Z"
                fill="#00AF54"
              />
            </svg>
            <p className="uppercase text-white mt-3">Rufen Sie uns an</p>
            <p className="mt-2 lg:text-2xl font-semibold text-white ">
              <a className="transition-ease-2 hover:text-lendis-main" href="tel:+493031199676">
                +49 (0) 30 311 99 676
              </a>
            </p>
          </div>
          <div className="mt-10 lg:mt-0 lg:w-1/3">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.82894 12.6059C6.44344 12.9915 6.19775 13.4019 6.04031 13.9242L6.03996 13.9253L4.56304 18.8165C4.37722 19.4318 4.96741 20.0271 5.58328 19.8457L10.4925 18.3997C11.0242 18.2433 11.4408 17.9958 11.8325 17.6033L24.0763 5.34424C25.1092 4.31026 23.4928 2.26256 22.807 1.57664C22.1192 0.888676 20.1103 -0.692283 19.079 0.340705L6.82894 12.6059ZM22.6844 4.40686L10.6936 16.4125C10.4673 16.6391 10.3012 16.7378 9.99413 16.8283L6.57959 17.8341L7.6058 14.4355C7.69674 14.1342 7.79525 13.9695 8.01752 13.747L20.0241 1.72553C21.1183 1.87784 22.5222 3.32278 22.6844 4.40686Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.67834 1.74764L10.6697 1.74764C11.1243 1.74764 11.4928 2.11663 11.4928 2.5718C11.4928 3.02697 11.1243 3.39596 10.6697 3.39596H3.70467C2.33468 3.39596 2.05839 3.6726 2.05839 5.04429V20.7034C2.05839 22.075 2.33468 22.3517 3.70467 22.3517H18.5212C19.8911 22.3517 20.1674 22.075 20.1674 20.7034V14.1576C20.1674 13.7024 20.536 13.3334 20.9906 13.3334C21.4452 13.3334 21.8137 13.7024 21.8137 14.1576V20.7297C21.8138 22.9195 20.7346 24.0001 18.5475 24H3.67833C1.49124 24.0001 0.412047 22.9195 0.41211 20.7297V5.01793C0.412047 2.82812 1.49125 1.74758 3.67834 1.74764Z"
                fill="white"
              />
            </svg>
            <p className="text-white uppercase mt-3">Schreiben Sie uns</p>
            <p className="text-white lg:text-2xl font-semibold mt-2 ">
              <a className="transition-ease-2 hover:text-lendis-main" href="mailto:info@lendis.io">
                info@lendis.io
              </a>
            </p>
          </div>
          <div className="lg:w-1/3">
            <div className="w-48">
              <SocialLinks />
            </div>
          </div>
        </div>
      </div>
      <footer className="text-white py-10 lg:px-16 px-8 bg-gray-900 ">
        <div className="container mx-auto">
          <div className="lg:flex">
            <div className="lg:flex lg:flex-col lg:w-1/2">
              <div className="lg:w-3/4">
                <h3 className="text-semibold text-2xl">We Power Your Office</h3>
                <p className="mt-6 leading-loose">
                  Das Angebot richtet sich ausschließlich an Unternehmen und Gewerbetreibende. Bei
                  Fragen kontaktieren Sie uns gerne jederzeit via Email.
                </p>
              </div>
              <div className="pt-10">
                <div className="pe-richsnippets" />
              </div>
              <div className="lg:flex lg:mt-6 lg:mx-0 mx-auto w-1/2">
                <div>
                  <img src={creditCards} alt="CreditCard" />
                </div>
                <div className="lg:ml-3">
                  <img src={paypal} alt="CreditCard" />
                </div>
              </div>
            </div>
            <div className="mt-10 lg:mt-0 lg:w-1/2 mx-auto ">
              <div className="flex flex-wrap">
                <div className="mt-10 lg:mt-0 mx-auto">
                  {/*ProvenExpert Badge*/}
                  <a
                    href="https://www.provenexpert.com/lendis-gmbh/?utm_source=Badge&amp;utm_medium=Badge&amp;utm_campaign=Badge"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span id="pe_badge_bmbxkdja" target="_blank" rel="noopener" />
                  </a>
                  {/*ProvenExpert Badge*/}
                </div>
                <div className="mt-10 lg:mt-0 mx-auto">
                  {/*ProvenExpert Bewertungssiegel*/}
                  <a
                    href="https://www.provenexpert.com/lendis-gmbh/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget"
                    title="Kundenbewertungen &amp; Erfahrungen zu Lendis GmbH. Mehr Infos anzeigen."
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <img
                      src="https://images.provenexpert.com/2e/18/25f2617126137415209f6cc81ca0/widget_square_180_de_0.png"
                      alt="Erfahrungen &amp; Bewertungen zu Lendis GmbH"
                      style={{ border: 0 }}
                    />
                  </a>
                  {/*ProvenExpert Bewertungssiegel*/}
                </div>
                <div className="mt-10 lg:mt-0 mx-auto">
                  {/*ProvenExpert Bewertungssiegel*/}
                  <a
                    href="https://www.kununu.com/de/lendis"
                    title="KUNUNU"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <Image
                      alt="kununu"
                      src="website/62_Kununu-topcompany-Freigestellt_1_ibi05j.png"
                      fixSize="180"
                    />
                  </a>
                  {/*ProvenExpert Bewertungssiegel*/}
                </div>
              </div>

              <div className="lg:flex lg:justify-end lg:w-full lg:mt-20">
                <SocialLinks itemCenter={false} />
              </div>
            </div>
          </div>
          <hr className="mt-10 bt-2 border-white" />
          <div className="flex flex-wrap mb-12 xl:flex-row-reverse">
            <div className="flex w-full xl:justify-end xl:w-2/3">
              <div className="flex mt-10">
                <div className="flex flex-wrap lg:justify-between w-full lg:flex-row">
                  <a
                    className="mx-2 transition-ease-2 hover:border-b-4 border-0 hover:border-white hover:pb-2 block lg:inline mb-6 text-white"
                    href="https://lendis.link/partner_tfs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Partner
                  </a>
                  <Link
                    className="mx-2 transition-ease-2 hover:border-b-4 border-0 hover:border-white hover:pb-2 block lg:inline mb-6 text-white"
                    to="/referenzen/"
                  >
                    Referenzen
                  </Link>
                  <Link
                    className="mx-2 transition-ease-2 hover:border-b-4 border-0 hover:border-white hover:pb-2 block lg:inline mb-6 text-white"
                    to="/ueber-uns/"
                  >
                    Über uns
                  </Link>
                  <Link
                    className="mx-2 transition-ease-2 hover:border-b-4 border-0 hover:border-white hover:pb-2 block lg:inline mb-6 text-white"
                    to="/karriere/"
                  >
                    Karriere
                  </Link>
                  <a
                    className="mx-2 transition-ease-2 hover:border-b-4 border-0 hover:border-white hover:pb-2 block lg:inline mb-6 text-white"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={process.env.MAGAZINE_LINK}
                  >
                    Magazin
                  </a>
                  <Link
                    className="mx-2 transition-ease-2 hover:border-b-4 border-0 hover:border-white hover:pb-2 block lg:inline mb-6 text-white"
                    to="/faq/"
                  >
                    FAQ
                  </Link>
                  <Link
                    className="mx-2 transition-ease-2 hover:border-b-4 border-0 hover:border-white hover:pb-2 block lg:inline mb-6 text-white"
                    to="/agb/"
                  >
                    AGB
                  </Link>
                  <Link
                    className="mx-2 transition-ease-2 hover:border-b-4 border-0 hover:border-white hover:pb-2 block lg:inline mb-6 text-white"
                    to="/datenschutz/"
                  >
                    Datenschutz
                  </Link>
                  <Link
                    className="mx-2 transition-ease-2 hover:border-b-4 border-0 hover:border-white hover:pb-2 block lg:inline mb-6 text-white"
                    to="/impressum/"
                  >
                    Impressum
                  </Link>
                  <Link
                    className="mx-2 transition-ease-2 hover:border-b-4 border-0 hover:border-white hover:pb-2 block lg:inline mb-6 text-white"
                    to="/kontakt/"
                  >
                    Kontakt
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex sm:flex w-full xl:mt-10 xl:w-1/3">
              <p className="">© 2018-{year} - Lendis GmbH.</p>
              <p className="ml-1">All Rights Resevered.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
