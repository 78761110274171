import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import LendisLogo from '../images/LendisLogo.svg'
import phone from '../images/svg/phone.svg'
import edit from '../images/svg/edit.svg'
import hamburgerMenuToggle from '../images/svg/hamburger-menu-toggle.svg'
import { Link } from 'gatsby'
import { globalHistory } from '@reach/router'

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [menuItems, setMenuItems] = useState([])

  useEffect(() => {
    const url = globalHistory.location.pathname
    setMenuItems([
      { link: 'vorteile', title: 'Vorteile', active: url.includes('vorteile') },
      { externalLink: 'https://homeoffice.lendis.io/', title: 'Home Office' },
      {
        link: 'bueroeinrichtung',
        title: 'Büroeinrichtung',
        active: url.includes('bueroeinrichtung') && !url.includes('kataloge'),
      },
      {
        link: 'elektronik',
        title: 'Elektronik',
        active: url.includes('elektronik') && !url.includes('kataloge'),
      },
      {
        link: 'bueroraeume',
        title: 'Räume',
        active: url.includes('bueroraeume') && !url.includes('kataloge'),
      },
      { link: 'kataloge', title: 'Kataloge', active: url.includes('kataloge') },
      {
        link: 'bueroplanung',
        title: 'Büroplanung',
        active: url.includes('bueroplanung') && !url.includes('kataloge'),
      },
      {
        link: 'services',
        title: 'Services',
        active: url.includes('services') && !url.includes('kataloge'),
      },
    ])
  }, [])

  return (
    <>
      <div className="fixed w-full z-30">
        <header>
          {/* Desktop Nav */}
          <div className="bg-white w-full lg:py-4 flex lg:items-center justify-between px-6 z-20 header-font">
            <div className="flex">
              <div>
                <Link to="/">
                  <img src={LendisLogo} alt="Lendis Logo" className=" pr-1 py-6" />
                </Link>
              </div>
              <div className="hidden xl:block xl:pr-2 py-4">
                <ul className="mt-4 h-10 items-start lg:flex lg:justify-between ">
                  {menuItems.map(item => {
                    return (
                      <li key={item.title} className="transition-ease-1 text-center px-1 ">
                        {item.externalLink ? (
                          <a
                            className="transition-ease-1 hover:border-b-4 border-0 hover:border-lendis-main hover:pb-2"
                            href={item.externalLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.title}
                          </a>
                        ) : (
                          <Link
                            activeClassName="border-lendis-main pb-2 border-b-4"
                            className={`transition-ease-1 hover:border-b-4 border-0 hover:border-lendis-main hover:pb-2 ${classnames(
                              {
                                'border-lendis-main pb-2 border-b-4': item.active,
                              },
                            )}`}
                            to={`/${item.link}/`}
                          >
                            {item.title}
                          </Link>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>

            <div className="hidden xl:block px-4 py-6 xl:flex xl:items-center">
              <ul className="mt-4 h-10 items-start lg:flex lg:justify-between mr-5">
                <li className="-m-1 -mt-2 bg-lendis-main border-2 border-lendis-main hover:bg-white hover:text-lendis-main ml-2 px-2 py-2 rounded text-center text-white transition-ease-5">
                  <Link to="/kontakt/"> Jetzt kontaktieren</Link>
                </li>
              </ul>
              <a
                className="flex transition-ease-1 hover:border-b-4 border-0 hover:border-lendis-main hover:pb-2 mr-2"
                href="tel:+493031199676"
              >
                <img src={phone} alt="Phone" />
                <p className="mx-4 ">+49 (0) 30 311 99 676</p>
              </a>
              <a
                className="flex transition-ease-1 hover:border-b-4 border-0 hover:border-lendis-main hover:pb-2"
                href="mailto:info@lendis.io"
              >
                <img src={edit} alt="Message" />
                <p className="mx-4">info@lendis.io</p>
              </a>
            </div>

            <MenuToggle onToggle={() => setIsMenuOpen(!isMenuOpen)} />
          </div>
          {/* Mobile Nav */}
          <div
            className={`transition-ease-3 xl:hidden bg-white h-full fixed z-20 w-3/4 top-0 left-0 flex overflow-y-scroll  ${classnames(
              {
                hidden: !isMenuOpen,
              },
            )}`}
          >
            <div className="w-full px-6 py-10">
              <Link to="/">
                <img src={LendisLogo} className="mb-8" alt="Lendis Logo" />
              </Link>
              <ul>
                <li className="mb-6">
                  <Link activeClassName="text-lendis-main" to="/vorteile/">
                    Vorteile
                  </Link>
                </li>
                <li className="mb-6">
                  <a activeClassName="text-lendis-main" href="https://homeoffice.lendis.io/">
                    Home Office
                  </a>
                </li>
                <li className="mb-6">
                  <Link activeClassName="text-lendis-main" to="/bueroeinrichtung/">
                    Büroeinrichtung
                  </Link>
                </li>
                <li className="mb-6">
                  <Link activeClassName="text-lendis-main" to="/elektronik/">
                    Elektronik
                  </Link>
                </li>
                <li className="mb-6">
                  <Link activeClassName="text-lendis-main" to="/bueroraeume/">
                    Räume
                  </Link>
                </li>
                <li className="mb-6">
                  <Link activeClassName="text-lendis-main" to="/kataloge/">
                    Kataloge
                  </Link>
                </li>
                <li className="mb-6">
                  <Link activeClassName="text-lendis-main" to="/bueroplanung/">
                    Büroplanung
                  </Link>
                </li>
                <li className="mb-6">
                  <Link activeClassName="text-lendis-main" to="/services/">
                    Services
                  </Link>
                </li>
                <li className="mb-6 border rounded border-lendis-main border-2 bg-lendis-main py-4 text-white pl-4 w-3/4 hover:bg-white hover:text-black">
                  <Link to="/kontakt/">Jetzt kontaktieren</Link>
                </li>
              </ul>
              <hr />
              <div className="mt-8 bg-gray-100 px-6 leading-loose pt-20 lg:pt-16 pb-8 lg:flex lg:items-center flex-wrap">
                <div className="w-full">
                  <a className="flex" href="tel:+493031199676">
                    <img src={phone} alt="Phone" />
                  </a>
                  <p className="my-4">+49 (0) 30 311 99 676</p>
                </div>
                <div className="w-full">
                  <a className="flex" href="mailto:info@lendis.io">
                    <img src={edit} alt="Message" />
                  </a>
                  <p className="my-4">info@lendis.io</p>
                </div>
              </div>
            </div>
          </div>
        </header>
        <button
          type="button"
          aria-label="menu"
          onClick={() => setIsMenuOpen(false)}
          className={`w-full h-full cursor-default xl:bg-transparent bg-black opacity-50 fixed inset-0 ${classnames(
            {
              hidden: !isMenuOpen,
            },
          )}`}
        />
      </div>
    </>
  )
}

const MenuToggle = ({ onToggle }) => (
  <button className="xl:hidden" onClick={() => onToggle()}>
    <img src={hamburgerMenuToggle} alt="" />
  </button>
)

Menu.MenuToggle = MenuToggle

export default Menu
