import React from 'react'

const SocialLinks = props => {
  const { itemCenter = true } = props
  return (
    <div className={`${itemCenter ? 'justify-between' : ''} flex items-center  mt-10`}>
      <div className="px-4">
        <a
          className="flex bg-transparent border-0 rounded-full transition-ease-2 hover:border-1 hover:border-lendis-focus hover:bg-lendis-main"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.facebook.com/lendis.gmbh/"
        >
          <svg
            width="36"
            height="35"
            viewBox="0 0 36 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                d="M35.4121 17.5C35.4121 7.83398 27.5781 0 17.9121 0C8.24609 0 0.412109 7.83398 0.412109 17.5C0.412109 26.2363 6.81055 33.4756 15.1777 34.7881V22.5586H10.7344V17.5H15.1777V13.6445C15.1777 9.25928 17.7891 6.83594 21.7881 6.83594C23.7021 6.83594 25.7051 7.17773 25.7051 7.17773V11.4844H23.4971C21.3232 11.4844 20.6465 12.8345 20.6465 14.2188V17.5H25.5L24.7241 22.5586H20.6465V34.7881C29.0137 33.4756 35.4121 26.2363 35.4121 17.5Z"
                fill="#ffffff"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="35" height="35" fill="white" transform="translate(0.412109)" />
              </clipPath>
            </defs>
          </svg>
        </a>
      </div>
      <div className="px-4">
        <a
          className="flex bg-transparent border-0 rounded-full transition-ease-2 hover:border-1 hover:border-lendis-focus hover:bg-lendis-main"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/company/lendis/"
        >
          <svg
            width="36"
            height="35"
            viewBox="0 0 36 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.9121 0C8.24713 0 0.412109 7.83502 0.412109 17.5C0.412109 27.165 8.24713 35 17.9121 35C27.5771 35 35.4121 27.165 35.4121 17.5C35.4121 7.83502 27.5771 0 17.9121 0ZM8.81334 14.4942H12.7792V26.4101H8.81334V14.4942ZM13.0403 10.8082C13.0146 9.63988 12.1791 8.75 10.8224 8.75C9.46568 8.75 8.57872 9.63988 8.57872 10.8082C8.57872 11.9524 9.43947 12.8679 10.7709 12.8679H10.7963C12.1791 12.8679 13.0403 11.9524 13.0403 10.8082ZM22.5155 14.2145C25.1253 14.2145 27.0818 15.9179 27.0818 19.5779L27.0816 26.4101H23.1159V20.0351C23.1159 18.4339 22.542 17.3412 21.1063 17.3412C20.0107 17.3412 19.358 18.0778 19.0714 18.7893C18.9666 19.0442 18.9408 19.3995 18.9408 19.7556V26.4104H14.9745C14.9745 26.4104 15.0267 15.6127 14.9745 14.4946H18.9408V16.1824C19.4672 15.371 20.4099 14.2145 22.5155 14.2145Z"
              fill="#ffffff"
            />
          </svg>
        </a>
      </div>
      <div className="px-4">
        <a
          className="flex bg-transparent border-0 rounded-full transition-ease-2 hover:border-1 hover:border-lendis-focus hover:bg-lendis-main"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/lendis.de/"
        >
          <svg
            width="36"
            height="35"
            viewBox="0 0 36 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.9121 0C8.24713 0 0.412109 7.83502 0.412109 17.5C0.412109 27.165 8.24713 35 17.9121 35C27.5771 35 35.4121 27.165 35.4121 17.5C35.4121 7.83502 27.5771 0 17.9121 0ZM14.0644 8.22301C15.06 8.1777 15.3781 8.16662 17.9129 8.16662H17.91C20.4456 8.16662 20.7625 8.1777 21.7581 8.22301C22.7517 8.26851 23.4303 8.42582 24.0253 8.65663C24.6398 8.89482 25.159 9.21371 25.6781 9.73289C26.1973 10.2517 26.5162 10.7724 26.7554 11.3863C26.9848 11.9797 27.1423 12.6579 27.189 13.6516C27.2337 14.6471 27.2454 14.9652 27.2454 17.5001C27.2454 20.0349 27.2337 20.3522 27.189 21.3478C27.1423 22.341 26.9848 23.0194 26.7554 23.6131C26.5162 24.2267 26.1973 24.7475 25.6781 25.2662C25.1596 25.7854 24.6396 26.1051 24.0259 26.3435C23.4321 26.5743 22.7531 26.7316 21.7595 26.7771C20.7639 26.8224 20.4468 26.8335 17.9118 26.8335C15.3771 26.8335 15.0592 26.8224 14.0637 26.7771C13.0702 26.7316 12.3918 26.5743 11.798 26.3435C11.1845 26.1051 10.6638 25.7854 10.1452 25.2662C9.6262 24.7475 9.30731 24.2267 9.06872 23.6129C8.83811 23.0194 8.6808 22.3412 8.63511 21.3476C8.58999 20.352 8.57872 20.0349 8.57872 17.5001C8.57872 14.9652 8.59038 14.6469 8.63491 13.6514C8.67963 12.6581 8.83714 11.9797 9.06853 11.3861C9.3077 10.7724 9.62659 10.2517 10.1458 9.73289C10.6645 9.21391 11.1853 8.89502 11.7991 8.65663C12.3926 8.42582 13.0708 8.26851 14.0644 8.22301Z"
              fill="#ffffff"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.0759 9.84852C17.2385 9.84827 17.4134 9.84835 17.6021 9.84843L17.9132 9.84852C20.4052 9.84852 20.7006 9.85747 21.6847 9.90219C22.5947 9.9438 23.0886 10.0959 23.4176 10.2236C23.8531 10.3928 24.1637 10.595 24.4901 10.9217C24.8168 11.2483 25.019 11.5595 25.1886 11.995C25.3163 12.3236 25.4686 12.8175 25.51 13.7275C25.5547 14.7114 25.5645 15.007 25.5645 17.4979C25.5645 19.9887 25.5547 20.2843 25.51 21.2682C25.4684 22.1782 25.3163 22.6721 25.1886 23.0007C25.0194 23.4363 24.8168 23.7464 24.4901 24.0729C24.1635 24.3995 23.8533 24.6018 23.4176 24.7709C23.089 24.8993 22.5947 25.0509 21.6847 25.0925C20.7008 25.1373 20.4052 25.147 17.9132 25.147C15.421 25.147 15.1256 25.1373 14.1417 25.0925C13.2317 25.0505 12.7378 24.8985 12.4086 24.7707C11.973 24.6016 11.6619 24.3993 11.3353 24.0727C11.0086 23.746 10.8064 23.4357 10.6368 22.9999C10.5091 22.6713 10.3568 22.1774 10.3154 21.2674C10.2707 20.2835 10.2617 19.9879 10.2617 17.4955C10.2617 15.0031 10.2707 14.7091 10.3154 13.7252C10.357 12.8152 10.5091 12.3213 10.6368 11.9923C10.806 11.5567 11.0086 11.2456 11.3353 10.919C11.6619 10.5923 11.973 10.3901 12.4086 10.2205C12.7376 10.0922 13.2317 9.9405 14.1417 9.89869C15.0027 9.8598 15.3364 9.84814 17.0759 9.84619V9.84852ZM22.8953 11.3983C22.277 11.3983 21.7753 11.8994 21.7753 12.5179C21.7753 13.1363 22.277 13.6379 22.8953 13.6379C23.5137 13.6379 24.0153 13.1363 24.0153 12.5179C24.0153 11.8996 23.5137 11.3979 22.8953 11.3979V11.3983ZM13.1201 17.5001C13.1201 14.8531 15.2662 12.707 17.9132 12.707H17.913C20.56 12.707 22.7056 14.8531 22.7056 17.5001C22.7056 20.1471 20.5602 22.2922 17.9132 22.2922C15.2662 22.2922 13.1201 20.1471 13.1201 17.5001Z"
              fill="#ffffff"
            />
            <path
              d="M17.9139 14.3892C19.632 14.3892 21.025 15.782 21.025 17.5003C21.025 19.2184 19.632 20.6114 17.9139 20.6114C16.1956 20.6114 14.8027 19.2184 14.8027 17.5003C14.8027 15.782 16.1956 14.3892 17.9139 14.3892Z"
              fill="#ffffff"
            />
          </svg>
        </a>
      </div>
    </div>
  )
}
export default SocialLinks
