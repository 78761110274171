import React from 'react'
import config from './config'
import useWindowWidth from '../../../hooks/window-size'
/**
 *
 * src : image source name
 * size : full, 1/2, 1/3 ....
 * fixSize : by px fix size
 * className : custom class for image
 * folderUrl : custom folder
 * maxWidth : max-width of image, default : 100%
 * @param props
 * @returns {*}
 * @constructor
 */
const Image = ({
  src,
  size = 'small',
  fixSize = false,
  className = '',
  maxWidth = config.maxWidth,
  alt = '',
  noChange = false,
  realSVG = false,
}) => {
  const widthSize = useWindowWidth(size, maxWidth, fixSize, src, noChange)
  if (realSVG) {
    return (
      <img
        src={`${process.env.CLOUDINARY_URL}/${src}`}
        alt={alt}
        width={fixSize}
        className={className}
      />
    )
  } else {
    return (
      <img src={`${process.env.CLOUDINARY_URL}/${widthSize}`} alt={alt} className={className} />
    )
  }
}

export default Image
