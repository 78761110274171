module.exports = {
  'about-us': {
    title: 'Erfahren Sie mehr über uns | Lendis',
    link: 'ueber-uns',
    description:
      'Lendis ist Ihr Allround-Partner für alle Aspekte des Office-Managements. Erfahren Sie mehr über uns und unsere Vision. | Lendis.io',
    og: {
      ogTitle: 'Über uns | Lendis - We Power Your Office',
      ogDescription:
        'Von Büroplanung bis zum einsatzbereiten Laptop inkl. Software ➣ Lendis unterstützt Sie dabei Ihr Büro effizient zu managen.',
      ogImage:
        'https://res.cloudinary.com/lendis-gmbh/image/upload/f_auto,w_853/website/63__DSC5489_ippxoa.jpg',
      ogImageAlt:
        'Moderner Open Space mit höhenverstellbaren Schreibtischen & Tischtrennwänden zur Schallisolierung',
    },
    robot: 'index, follow',
  },
  reference: {
    title: 'Zufriedene Kunden von Lendis',
    link: 'referenzen',
    description:
      'Bereits über 350 Kunden vertrauen auf Lendis. Entdecken Sie ihre Büro. Erfahren Sie hier mehr dazu! Lendis.io',
    og: {
      ogTitle: 'Referenzen | Lendis - We Power Your Office',
      ogDescription:
        'Über 350 Kunden vertrauen bereits Lendis · Von der Planung zum fertigen Büro ➣ Jetzt eingerichtete Büros entdecken',
      ogImage:
        'https://res.cloudinary.com/lendis-gmbh/image/upload/v1580998802/website/marketing/Open%20Graphs/tmb0t7cmszenjvecvjkz.jpg',
      ogImageAlt:
        'Industriell eingerichteter Konferenzraum mit dunklen Konferenzstühlen, einem langen, hölzernen Konferenztisch und Sideboards',
    },
    robot: 'index, follow',
  },
  'our-service': {
    title: 'Unsere Services - Jetzt Informationen anfragen | Lendis',
    link: 'services/unsere-services',
    description:
      'Erhalten Sie jetzt weitere Informationen zu all unseren Büro-Services. Jetzt einfach anfragen! | Lendis.io',
    robot: 'noindex, follow',
  },
  'office-planning': {
    title: 'Büroplanung mit Lendis | Büroeinrichtung online planen',
    link: 'bueroplanung',
    description:
      'Ausstattung und Interior-Planung für Ihr Büro ✓ Individuelle Planung abgestimmt auf Ihre Bedürfnisse ✓ Jetzt mehr erfahren! Lendis.io',
    og: {
      ogTitle: '📐Büroplanung | Lendis - We Power Your Office',
      ogDescription:
        'Von Grundrissplanung bis zum einsatzbereiten Laptop inkl. Software · Büromanagement einfach und komplett digital',
      ogImage:
        'https://res.cloudinary.com/lendis-gmbh/image/upload/v1580999015/website/marketing/Open%20Graphs/kioe6lrmtnlzxp4tfjx9.jpg',
      ogImageAlt: 'Grundriss-Skizze zur Planung von Büroräumen',
    },
    robot: 'index, follow',
  },
  'floor-plan': {
    title: 'Individuelle Grundrissplanung für Ihr Büro - Jetzt anfragen | Lendis',
    link: 'bueroplanung/grundrissplanung',
    description:
      'Fragen Sie online eine individuelle Grundrissplanung an ✓ Kostenlos & unverbindlich ✓ Jetzt mehr erfahren | Lendis.io',
    og: {
      ogTitle: '📐Grundrissplanung | Lendis - We Power Your Office',
      ogDescription:
        'Von Grundrissplanung bis zum einsatzbereiten Büro · Büroplanung · Ausstattung · Lieferung · Montage ➣ Jetzt beraten lassen',
      ogImage:
        'https://res.cloudinary.com/lendis-gmbh/image/upload/v1580999015/website/marketing/Open%20Graphs/kioe6lrmtnlzxp4tfjx9.jpg',
      ogImageAlt: 'Mann zeichnet Notizen auf Grundriss-Skizze eines Bürogebäudes ein',
    },
    robot: 'noindex, follow',
  },
  'digital-office-management': {
    title: 'Digital Office Management - Büro digital verwalten | Lendis',
    link: 'bueroplanung/digital-office-management',
    description:
      'Melden Sie sich zum kostenlosen Beta-Test des digitalen Office Dashboards an! Jetzt mehr erfahren | Lendis.io',
    og: {
      ogTitle: 'Büromanagement | Lendis - We Power Your Office',
      ogDescription:
        'Büromanagement einfach und komplett digital · Ausstattungen, Services und Software per Klicks bestellen ➣ Jetzt testen',
      ogImage:
        'https://res.cloudinary.com/lendis-gmbh/image/upload/f_auto,w_640/website/office_planning/54_digital_office_management_-_dashboard_b7uizn.png',
      ogImageAlt:
        'Büromanagement-Dashboard im Lendis Interface zur digitalen Verwaltung von Ausstattungen, Services und Software',
    },
    robot: 'noindex, follow',
  },
  'check-cost': {
    title: 'Kosten für Büroausstattung kostenlos prüfen | Lendis',
    link: 'bueroplanung/kostencheck',
    description:
      'Ermitteln Sie schnell online die Kosten für Ihre Büroausstattung. Kostenlos & unverbindlich ✓ Jetzt mehr erfahren | Lendis.io',
    og: {
      ogTitle: 'Kostencheck | Lendis - We Power Your Office',
      ogDescription:
        'Was sollte der Betrieb Ihres Büros kosten? Zahlen Sie zuviel für Ihren Arbeitsplatz?',
      ogImage:
        'https://res.cloudinary.com/lendis-gmbh/image/upload/v1580999501/website/marketing/Open%20Graphs/nkhljtmmpd3jmtrtuh7k.jpg',
      ogImageAlt: 'Heller, einfacher Taschenrechner auf kariertem Papier',
    },
    robot: 'index, follow',
  },
  '404': {
    title: 'Lendis | 404',
    link: '404',
  },
  benefits: {
    title: 'Ihre Vorteile beim Mieten mit Lendis',
    link: 'vorteile',
    description:
      'Mieten statt kaufen! Erfahren Sie jetzt mehr über die Vorteile beim Mieten mit Lendis!',
    og: {
      ogTitle: 'Vorteile | Lendis - We Power Your Office',
      ogDescription:
        'Mieten statt kaufen | Die Vorteile: Nachhaltig · Stressfrei · Monatlich kündbar · Keine hohen Investitionen',
      ogImage:
        'https://res.cloudinary.com/lendis-gmbh/image/upload/f_auto,w_853/website/63__DSC5489_ippxoa.jpg',
      ogImageAlt: 'Teammeeting und Teamzusammenhalt im Großraumbüro',
    },
    robot: 'noindex, follow',
  },
  contact: {
    title: 'Kontaktieren Sie uns bei Fragen zu Produkten & Services | Lendis',
    link: 'kontakt',
    description:
      'Sie haben Fragen zu uns, unseren Produkten und unseren Services? Kontaktieren Sie uns gern. | Lendis.io',
    og: {
      ogTitle: ' Kontakt | Lendis - We Power Your Office',
      ogDescription:
        'Noch Fragen? Ob am Telefon, per Mail oder über unseren Chat ➣ Wir beraten Sie gerne',
      ogImage:
        'https://res.cloudinary.com/lendis-gmbh/image/upload/f_auto,w_853/website/63__DSC5489_ippxoa.jpg',
      ogImageAlt: 'Aufgeräumtes, modernes Büro inkl. Loungebereich mit Loungesofa und Stehlampe',
    },
    robot: 'index, follow',
  },
  'data-privacy': {
    title: 'Datenschutz | Lendis',
    link: 'datenschutz',
    description:
      'Datenschutz | Hier finden Sie wichtige Informationen zum Thema Datenschutz bei Lendis.',
    og: {
      ogTitle: 'Datenschutz | Lendis - We Power Your Office',
      ogDescription:
        'Wir nehmen den Schutz Ihrer Privatsphäre und Ihrer personenbezogenen Daten sehr ernst',
      ogImage:
        'https://res.cloudinary.com/lendis-gmbh/image/upload/f_auto,w_853/website/63__DSC5489_ippxoa.jpg',
      ogImageAlt: 'Schwarz-weißes Großraumbüro mit Schreibtischen, Bürostühlen und Pflanzen',
    },
    robot: 'noindex, follow',
  },
  faq: {
    title: 'Fragen & Antworten | Mieten mit Lendis',
    link: 'faq',
    description:
      'Finden Sie Antworten zu den häufigsten Fragen zur Miete von Büroausstattung mit Lendis. Jetzt mehr erfahren! Lendis.io',
    og: {
      ogTitle: 'FAQs | Lendis - We Power Your Office',
      ogDescription:
        'Wie Funktioniert Lendis? Lieferungen | Montage | Laufzeit | Versicherung | Rechtliches',
      ogImage:
        'https://res.cloudinary.com/lendis-gmbh/image/upload/f_auto,w_853/website/63__DSC5489_ippxoa.jpg',
      ogImageAlt:
        'Modern-industrielles Großraumbüro mit Schreibtischen, Bürostühlen, Monitoren und Stehlampen',
    },
    robot: 'index, follow',
  },
  imprint: {
    title: 'Impressum | Lendis',
    link: 'impressum',
    description: 'Impressum | Hier finden Sie wichtige Informationen über Lendis',
    og: {
      ogTitle: 'Impressum | Lendis - We Power Your Office',
      ogDescription:
        'Lendis GmbH · E-Mail: info@lendis.io · Tel: +49 (0) 30 311 99 676 · Montag – Freitag von 08.00 Uhr bis 20.00 Uhr',
      ogImage:
        'https://res.cloudinary.com/lendis-gmbh/image/upload/f_auto,w_853/website/63__DSC5489_ippxoa.jpg',
      ogImageAlt: 'Aufgeräumtes, modernes Büro inkl. Loungebereich mit Loungesofa und Stehlampe',
    },
    robot: 'noindex, follow',
  },
  index: {
    title: 'Büroausstattung mieten & online verwalten - Lendis.io',
    link: '',
    description:
      'Mieten Sie Büroeinrichtung, Elektronik & Services flexibel online✓ Behalten Sie stets den Überblick über Ihre Büroausstattung✓ Mehr erfahren ▻Lendis.io',
    og: {
      ogTitle: 'Lendis | We Power Your Office | Büroausstattung mieten',
      ogDescription:
        'Elektronik, Software & Büromöbel flexibel und günstig mit Lendis mieten. Inkl. Lieferung und Installation.\t',
      ogImage:
        'https://res.cloudinary.com/lendis-gmbh/image/upload/f_auto,w_853/website/63__DSC5489_ippxoa.jpg',
      ogImageAlt: 'Hell eingerichtetes Großraumbüro | Moderne Büroausstattung von Lendis',
    },
    robot: 'index, follow',
  },
  tos: {
    title: 'Allgemeine Geschäftsbedingungen | Lendis',
    link: 'agb',
    description: 'AGB | Unsere Allgemeinen Geschäftsbedingungen. | Lendis.io',
    og: {
      ogTitle: 'AGBs | Lendis - We Power Your Office',
      ogDescription: 'Unsere Allgemeinen Geschäftsbedingungen',
      ogImage:
        'https://res.cloudinary.com/lendis-gmbh/image/upload/f_auto,w_853/website/63__DSC5489_ippxoa.jpg',
      ogImageAlt: 'Schwarz-weißes Großraumbüro mit Schreibtischen, Bürostühlen und Pflanzen',
    },
    robot: 'noindex, follow',
  },
  tigerfacilityservices: {
    title: 'Kostencheck | Lendis - We Power Your Office',
    link: 'bueroplanung/kostencheck',
    description:
      'Was sollte der Betrieb Ihres Büros kosten? Zahlen Sie zuviel für Ihren Arbeitsplatz? Der Lendis - Kostencheck',
  },
  'product-services': {
    link: 'services',
    title: 'Büroservices flexibel mieten mit Lendis',
    description:
      'Services rund ums Büro flexibel mieten ✓ Handyverträge, Versicherungen, Lieferservices oder Reinigungs-Services ✓ Bequem buchen über Lendis!',
    og: {
      ogTitle: '📱 Büro-Services | Lendis - We Power Your Office',
      ogDescription:
        'Monatlich kündbare Handyvertrage · Kaffeebohnen · Snacks & Getränke · Reinigung · Internet · Versicherung',
      ogImage:
        'https://res.cloudinary.com/lendis-gmbh/image/upload/v1580999015/website/marketing/Open%20Graphs/kioe6lrmtnlzxp4tfjx9.jpg',
      ogImageAlt: 'Nutzerin eines Smartphones erhält Anruf einer Arbeitskollegin',
    },
    robot: 'index, follow',
  },
  'product-software': {
    link: 'software',
    title: 'Software für Büro mieten | Lendis',
    description:
      'Software für den Einsatz im Büro flexibel mieten ✓ MS Office, Slack, Tableua uvm. ✓ Inklusive Installation & Einrichtung ✓ Mehr dazu erfahren Sie hier!',
    og: {
      ogTitle: 'Software | Lendis - We Power Your Office',
      ogDescription:
        'Ob mit oder ohne Laptop, mieten Sie Ihre Software-Pakete bequem von Lendis · Office Basics · Analytics · Communication',
      ogImage:
        'https://res.cloudinary.com/lendis-gmbh/image/upload/v1580998797/website/marketing/Open%20Graphs/uhsqyqsp3bwunnk3jgyo.jpg',
      ogImageAlt:
        'Schreibtisch mit MacBook, iPhone, Maus, geräuschunterdrückenden Kopfhöhrern und Notizblock',
    },
    robot: 'index, follow',
  },
  'kataloge-highlights': {
    link: 'kataloge/highlights',
    title: 'Katalog für Büromöbel - Unsere Highlights | Lendis',
    description:
      'Finden Sie die Highlight-Produkte unserer Büroausstattung im Online-Katalog. Jetzt bequem online anfordern. | Lendis.io',
    robot: 'noindex, follow',
  },
  'kataloge-elektronik': {
    link: 'kataloge/elektronik',
    title: 'Elektronik für Büro flexibel mieten - Jetzt Katalog anfordern | Lendis',
    description:
      'Elektronik zum Einsatz im Büro flexibel mieten ✓ Inkl. Lieferung & Aufbau ✓ Alle Produkte in unserem Online-Katalog ✓Jetzt mehr erfahren | Lendis.io',
    robot: 'noindex, follow',
  },
  'kataloge-software': {
    link: 'kataloge/software',
    title: 'Software für Büro mieten - Jetzt Katalog anfordern | Lendis',
    description:
      'Software zum Einsatz im Büro flexibel mieten ✓ Inkl. Lieferung & Aufbau ✓ Alle Produkte in unserem Online-Katalog ✓Jetzt mehr erfahren | Lendis.io',
    robot: 'noindex, follow',
  },
  'kataloge-akustikloesungen': {
    link: 'kataloge/akustikloesungen',
    title: 'Katalog für unsere Akustiklösungen - Jetzt anfordern | Lendis',
    description:
      'Akustik-Möbel für das Büro flexibel mieten ✓ Alle Produkte in unserem Online-Katalog ✓ Jetzt mehr erfahren | Lendis.io',
    robot: 'noindex, follow',
  },
  'kataloge-telefon-und-meetingboxen': {
    link: 'kataloge/telefon-und-meetingboxen',
    title: 'Katalog für unsere Telefonboxen - Jetzt anfordern | Lendis',
    description:
      'Telefonkabinen für das Büro flexibel mieten ✓ Alle Telefonboxen zur Miete in unserem Online-Katalog ✓ Jetzt mehr erfahren | Lendis.io',
    robot: 'noindex, follow',
  },
  'kataloge-kaffeeautomaten': {
    link: 'kataloge/kaffeeautomaten',
    title: 'Kaffeeautomaten & -maschinen mieten - Jetzt Katalog anfordern | Lendis',
    description:
      'Kaffeeautomaten und -maschinen für das Büro flexibel mieten ✓ Inkl. Lieferung & Aufbau ✓ Jetzt mehr erfahren | Lendis.io',
    robot: 'noindex, follow',
  },
  'kataloge-lounge': {
    link: 'kataloge/lounge',
    title: 'Loungemöbel mieten - Sessel, Sofas & mehr - Jetzt Katalog anfordern',
    description:
      'Loungemöbel flexibel mieten ✓ Inkl. Lieferung & Aufbau ✓ Alle Sessel, Sofas und mehr in unserem Online-Katalog ✓ Jetzt mehr erfahren | Lendis.io',
    robot: 'noindex, follow',
  },
  'kataloge-bueropflanzen': {
    link: 'kataloge/bueropflanzen',
    title: 'Büropflanzen mieten - Jetzt Katalog anfordern | Lendis',
    description:
      'Pflanzen für ein grünes Büro flexibel mieten ✓ Inkl. Lieferung ✓  Alle Produkte in unserem Online-Katalog ✓ Jetzt mehr erfahren | Lendis.io',
    robot: 'noindex, follow',
  },
  'kataloge-service': {
    link: 'kataloge/service',
    title: 'Lendis | Services',
  },
  'kataloge-bueroeinrichtung': {
    link: 'kataloge/bueroeinrichtung',
    title: 'Büromöbel mieten - Jetzt Katalog anfordern | Lendis',
    description:
      'Büromöbel flexibel mieten mit Lendis ✓ Inkl. Lieferung ✓  Alle Produkte in unserem Online-Katalog ✓ Jetzt mehr erfahren | Lendis.io',
    robot: 'noindex, follow',
  },
  'catalog-overview': {
    link: 'kataloge',
    title: 'Unsere Büromöbel- & Elektronik-Kataloge | Lendis.io',
    description:
      'Unsere Büroausstattung✓ Büroeinrichtung, Elektronik, Services✓ Lernen Sie unser Produktsortiment kennen✓ Jetzt Katalog herunterladen ► Lendis.io',
    robot: 'index, follow',
  },
}
