import React from 'react'
import PropTypes from 'prop-types'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'
import './layout.css'
import useLoadScript from '../hooks/FooterScript'
import useMarketingParamsToCookies from '../hooks/marketingParamsToCookies'

const Layout = ({ children }) => {
  useLoadScript()
  useMarketingParamsToCookies()

  return (
    <>
      <CookieConsent location="bottom" buttonText="Ok, verstanden" cookieName="lendisCookieConsent">
        Diese Website nutzt Cookies, um bestmögliche Funktionalität bieten zu können. Ihre
        Zustimmung ist freiwillig und kann jederzeit widerrufen werden.{' '}
        <Link className="underline" to="/datenschutz/">
          Datenschutzerklärung.
        </Link>
      </CookieConsent>
      <link
        href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,400&display=swap"
        rel="stylesheet"
      />
      <div className="font-sans antialiased">
        <main className="relative">{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
