import { useEffect } from 'react'

function useLoadScript() {
  useEffect(() => {
    const scriptScr = [
      'https://www.provenexpert.com/widget/richsnippet.js?u=1RKZlNmAk52AiWaZ5RUZmOKAlZQpiWaZ&v=3',
      'https://www.provenexpert.com/badge/topservice.js?id=1RKZlNmAk52AiWaZ5RUZmOKAlZQpiWaZ&w=180&key=bmbxkdja',
    ]
    let scriptTags = []

    scriptScr.forEach(function(ScriptSrc) {
      const scriptTag = document.createElement('script')
      scriptTag.src = ScriptSrc
      document.body.appendChild(scriptTag)
      scriptTags.push(scriptTag)
    })

    return () => {
      scriptTags.forEach(function(scriptTag) {
        document.body.removeChild(scriptTag)
      })
    }
  }, [])
}

export default useLoadScript
