import { useEffect } from 'react'
import Cookies from 'js-cookie'

function useMarketingParamsToCookies() {
  useEffect(() => {
    const cookie_domain = process.env.COOKIE_DOMAIN

    function getParameterByName(name) {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')

      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
      const results = regex.exec(window.location.search)

      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
    }

    function getCookieByName(name) {
      return Cookies.get(name)
    }

    const source = getParameterByName('utm_source')
    const medium = getParameterByName('utm_medium')
    const campaign = getParameterByName('utm_campaign')
    const content = getParameterByName('utm_content')
    const term = getParameterByName('utm_term')
    const gclid = getParameterByName('gclid')
    const adgroupid = getParameterByName('adgroupid')

    //delete cookie if a new parameter is empty
    if (
      (source === '' ||
        medium === '' ||
        campaign === '' ||
        content === '' ||
        term === '' ||
        gclid === '' ||
        adgroupid === '') &&
      !(
        source === '' &&
        medium === '' &&
        campaign === '' &&
        content === '' &&
        term === '' &&
        gclid === '' &&
        adgroupid === ''
      )
    ) {
      Cookies.remove('utm_source', { expires: 999, domain: cookie_domain })
      Cookies.remove('utm_medium', { expires: 999, domain: cookie_domain })
      Cookies.remove('utm_campaign', { expires: 999, domain: cookie_domain })
      Cookies.remove('utm_content', { expires: 999, domain: cookie_domain })
      Cookies.remove('utm_term', { expires: 999, domain: cookie_domain })
      Cookies.remove('gclid', { expires: 999, domain: cookie_domain })
      Cookies.remove('adgroupid', { expires: 999, domain: cookie_domain })
    }

    function setCookies() {
      if (
        (!getCookieByName('utm_source') && source) ||
        (Cookies.get('utm_source') !== source && source)
      ) {
        Cookies.set('utm_source', source, {
          expires: 999,
          domain: cookie_domain,
        })
      }

      if (
        (!getCookieByName('utm_medium') && medium) ||
        (Cookies.get('utm_medium') !== medium && medium)
      ) {
        Cookies.set('utm_medium', medium, { expires: 999, domain: cookie_domain })
      }

      if (
        (!getCookieByName('utm_campaign') && campaign) ||
        (Cookies.get('utm_campaign') !== campaign && campaign)
      ) {
        Cookies.set('utm_campaign', campaign, { expires: 999, domain: cookie_domain })
      }

      if (
        (!getCookieByName('utm_content') && content) ||
        (Cookies.get('utm_content') !== content && content)
      ) {
        Cookies.set('utm_content', content, { expires: 999, domain: cookie_domain })
      }

      if ((!getCookieByName('utm_term') && term) || (Cookies.get('utm_term') !== term && term)) {
        Cookies.set('utm_term', term, { expires: 999, domain: cookie_domain })
      }

      if ((!getCookieByName('gclid') && gclid) || (Cookies.get('gclid') !== gclid && gclid)) {
        Cookies.set('gclid', gclid, { expires: 999, domain: cookie_domain })
      }

      if (
        (!getCookieByName('adgroupid') && adgroupid) ||
        (Cookies.get('adgroupid') !== adgroupid && adgroupid)
      ) {
        Cookies.set('adgroupid', adgroupid, { expires: 999, domain: cookie_domain })
      }
    }

    setCookies()
  }, [])
}

export default useMarketingParamsToCookies
