import config from '../components/Lendis/Image/config'
import { useEffect, useState } from 'react'

function useWindowWidth(size, maxWidth, fixSize, src, noChange) {
  const isClient = typeof window === 'object'
  function maxContainerSize(screenWidth) {
    let sortable = []
    for (let data in config.screens) {
      sortable.push({ size: config.screens[data] })
    }
    sortable.sort(function(a, b) {
      return a[1] - b[1]
    })
    let imageSize = sortable[sortable.length - 1]['size']
    for (let data in sortable) {
      if (screenWidth < sortable[data].size) {
        imageSize = sortable[data].size
        break
      }
    }
    return imageSize
  }
  const [imageSize, setImageSize] = useState(
    getSize(src, noChange, fixSize, isClient, maxWidth, size, maxContainerSize),
  )
  useEffect(() => {
    if (!isClient) {
      return false
    }
    function handleResize() {
      setImageSize(getSize(src, noChange, fixSize, isClient, maxWidth, size, maxContainerSize))
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isClient, fixSize, maxWidth, noChange, src, size])
  return imageSize
}

function setImageWidth(width, maxWidth, size, src) {
  let ratio = parseInt(width)
  if (maxWidth !== width) {
    ratio = maxWidth
  }
  if (size !== 'full') {
    ratio = !isNaN((config.scale[size] / 100) * ratio) ? (config.scale[size] / 100) * ratio : ratio
    const regex = /(sm|md|lg|xl):(full|\d\/\d)/gm
    let m
    // @see https://regex101.com/codegen?language=javascript for why this while looks strange
    while ((m = regex.exec(size)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++
      }
      if (parseInt(width) > parseInt(config.screens[m[1]])) {
        ratio = (config.scale[m[2]] / 100) * ratio
      } else {
        let x = size.match('def:(full|[0-9]/[0-9])')
        if (x) {
          ratio = (config.scale[x[1]] / 100) * ratio
        } else {
          ratio = (config.scale['full'] / 100) * ratio
        }
      }
    }
  }

  if (isNaN(ratio)) {
    ratio = parseInt(width)
  }

  return `f_auto,w_${Math.trunc(ratio)}/${src}`
}

function getSize(src, noChange, fixSize, isClient, maxWidth, size, maxContainerSize, test) {
  if (noChange === true) {
    return `f_auto/${src}`
  }
  if (fixSize !== false) {
    return `f_auto,w_${parseInt(fixSize)}/${src}`
  }
  const sizes = {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  }
  let imageWidth = sizes.width
  if (maxWidth === 'full') {
    imageWidth = sizes.width
  }
  if (config.fullContainer !== true) {
    imageWidth = maxContainerSize(sizes.width)
  }
  imageWidth = parseInt(imageWidth)
  return setImageWidth(sizes.width, imageWidth, size, src, test)
}

export default useWindowWidth
