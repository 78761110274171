import React from 'react'
import { Helmet } from 'react-helmet'

const MetaConfig = require('../config/meta.config')
/**
 * Header config
 * @param page - string
 * @param isProductPage - boolean
 * @param productMetaData - object
 * @returns {*}
 * @constructor
 */
const Header = ({ page = null, isProductPage = false, productMetaData = {} }) => {
  let pageMetaData = {}
  if (isProductPage) {
    pageMetaData = productMetaData
  } else {
    pageMetaData = MetaConfig[page]
  }

  if (!pageMetaData) {
    throw new Error('config dose not exist')
  }

  const ogs = [
    { name: 'og:title', contentKey: 'ogTitle' },
    { name: 'og:description', contentKey: 'ogDescription' },
    { name: 'og:image', contentKey: 'ogImage' },
    { name: 'og:image:alt', contentKey: 'ogImageAlt' },
  ]
  const meta = [
    { name: 'description', content: pageMetaData.description },
    {
      name: 'robots',
      content: pageMetaData.robot || 'noindex, nofollow',
    },
    { name: 'charset', content: 'utf-8' },
  ]

  pageMetaData.og &&
    ogs.forEach(currentOg => {
      if (pageMetaData.og[currentOg.contentKey]) {
        meta.push({ name: currentOg.name, content: pageMetaData.og[currentOg.contentKey] })
      }
    })

  let href = 'https://www.lendis.io/'
  if (pageMetaData.link) {
    href = href + pageMetaData.link.replace(/\/+$/g, '').replace(/^\/|\/%/g, '') + '/'
  }

  return (
    <Helmet
      title={pageMetaData.title}
      meta={meta}
      link={[{ rel: 'canonical', href: href }]}
      defer={false}
    >
      <html lang="de" />
    </Helmet>
  )
}

export default Header
